import React from 'react'
import styled from 'styled-components';
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { CallToAction, GetStarted } from '../../components/CallToAction'
import { Quote } from '../../components/Quote'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';
import { VideoFrame } from '../../components/VideoFrame';

import { salesLeaders } from '../../common/Util';

import SfOnLaptop from '../images/sfOnLaptop.png'
import SfNotes from '../images/sfNotes.svg'
import TeamDashHalf from '../images/teamDashHalf.svg'

import OldWay from '../images/oldWay.svg';
import ActionEvent from '../images/actionEvent.svg';
import SalesforceDashboard from '../images/salesforceDashboard.png'
import IntegrationsGrid from '../images/integrationsGrid.svg'



const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | For Marketing Teams</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='wave'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            What Truly Can Do For You
          </PageTitle2>
          <RoleSelector>
            <a href="/solutions/for-revenue-ops">
              <Role>Revenue Operations</Role>
            </a>
            <a href="/solutions/for-analytics-teams">
              <Role>Analytics Teams</Role>
            </a>
            <a href="/solutions/for-sales-leaders">
              <Role>Sales Teams</Role>
            </a>
            <a href="/solutions/for-marketing">
              <ActiveRole>Marketing Teams</ActiveRole>
            </a>
          </RoleSelector>

          <br/><br/>
          
          <Title3 as="h2" color={colors.accentFog} bold mt="3rem" mb="1.5rem">
              Automate The Customer Journey <br/> With Less Dependence On Sales
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem" textAlign="left">
               Today, marketing automation depends on sales to feed the CRM with data, which often doesn't happen.  Software robots automate these processes in real-time to give marketing real-time visibility into the market and funnel.
              <br/><br/><br/>
              <b><u>Scale Market Research On Linkedin</u></b>. Since Linkedin has no API, you probably rely on complex processes to drive your reps to do prospecting/research, which is expensive and difficult to scale (528X more expensive than a robot!).  Truly gives you an elastically scalable 
              <br/><br/><br/>
              <b><u>Get Perfect CRM Data</u></b>. Automate core CRM tasks like contact creation, opporutnity creation and opportunity progression.  Create rules that fire in real-time and retroactively to mine/cleanse your CRM data.
              <br/><br/><br/>
               <b><u>Delight Reps & Customers</u></b>. By taking bureaucracy out of the sales job and the customer journey, you can put everyone's focus on the handful of things that actually matter and drive better retention.
              <br/><br/><br/>
              </Large>

              <VideoFrameContainer>
                <VideoFrame caption="Auto-Create Contacts" background="Contacts" videoLink="https://player.vimeo.com/video/618313401?h=a5ff00e17d&amp;badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479" />
              </VideoFrameContainer>
          
        </Box>
      </Section>
    </WrapperBackground>

  </Default>
)

export default SolutionsPage

const RoleSelector = styled.div`
  justify-content: center;
  border: white 2px solid;
  border-radius: 32pt;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-align: center;
  flex-grow: 0;
  width: 500px;
  margin: 0 auto;
`;

const VideoFrameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-align: center;
  justify-content: center;
`

const Role = styled.div`
  color: white;
  overflow: hidden;
  padding: 12px 16px;
  margin: 4px 4px;
  border-radius: 32pt;
  display: flex;
  flex-align: center;
  flex-grow: 1;
  justify-content: center;

  :hover{
    background-color: rgba(255,255,255,0.3);
    animation: background color 0.2s;
  }
`;

const ActiveRole = styled.div`
  color: black;
  font-weight: bold;
  border-radius: 32pt;
  background-color: white;
  overflow: hidden;
  padding: 12px 16px;
  margin: 4px 4px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;